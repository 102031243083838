import axios from 'axios';
export default class ApiService {
    constructor() {
        this.baseUrl = `${process.env.REACT_APP_BASE_API_URL}/api`;
        this.http = axios.create();
        this.http.interceptors.response.use(
            function (response) {
                return response;
            },
            function (error) {
                const response =
                    error.response && error.response.data
                        ? error.response.data.message
                        : 'An error occurred.';
                return Promise.reject(new Error(response));
            }
        );
    }

    map() {
        return {
            getMap: (year, reportedProperties) => {
                const token = process.env.REACT_APP_MAP_HASH;
                const { required, voluntary } = reportedProperties;

                //remove only_compliant=1 to include buildings that are pending revisions
                let url = `${this.baseUrl}/region/map?hash=${token}&year=${year}&valid_id=1&parent_property=1`;

                if (required) url += '&required_to_report=1';
                if (voluntary) url += '&voluntarily_reported=1';

                return this.http.get(url);
            },
            getUseTypes: (grouped = true) => {
                let url = `${this.baseUrl}/region/map/usetypes`;
                if (!grouped) url += '/ungrouped';
                return this.http.get(url);
            },
        };
    }
}
