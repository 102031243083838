import { useEffect, useState, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTheme } from '@material-ui/core';
import { setSelectedBuilding } from 'config/appSlice';
import { GeoJsonLayer } from '@deck.gl/layers';
import { hexToRgb } from 'utils/colorConverter';
import { isEmpty, hasOwn } from 'utils/checkValue';
import {capitalize, isUndisclosed, numberFormat} from 'utils/formatter';
import { badToGood, goodToBad, neutral, status, excluded } from 'utils/colors';
import filterData from 'utils/filterData';

export const BUILDINGS_LAYER_ID = 'buildingsLayer';

function BuildingsLayer() {
    const theme = useTheme();
    const dispatch = useDispatch();
    const viewState = useSelector((state) => state.carto.viewState);
    const data = useSelector((state) => state.app.data);
    const filters = useSelector((state) => state.app.filters);
    const selectedFilter = useSelector((state) => state.app.selectedFilter);

    const [mapGeoJson, setMapGeoJson] = useState(null);

    const setColor = useCallback(
        (d) => {
            const colorMap = {
                badToGood,
                goodToBad,
                neutral,
                status,
            };
            const {
                colors,
                key,
                slots,
                type,
                ranges,
                min,
                max,
                exceedMin,
                exceedMax,
                showCompliantOnly,
            } = filters[selectedFilter];
            const value = d[key];
            if (
                isEmpty(value) ||
                (showCompliantOnly && d.normalized_status !== 'in compliance')
            )
                return hexToRgb(excluded(theme));

            switch (type) {
                case 'range':
                    for (let i = 0; i < ranges.length; i++) {
                        const [low, high] = ranges[i];
                        if (
                            (value >= low && value < high + 1) ||
                            (value < min && low === min && exceedMin) ||
                            (value > max && high === max && exceedMax)
                        ) {
                            return hexToRgb(colorMap[colors](theme, 10 / slots)[i]);
                        }
                    }
                    break;
                default:
                    const index = ranges.indexOf(value);
                    if (index > -1) {
                        return hexToRgb(colorMap[colors](theme)[index]);
                    }
                    break;
            }
        },
        [theme, filters, selectedFilter]
    );

    const setPopup = useCallback(
        (info) => {
            if (info?.object) {
                const coordinate = [
                    info.object.properties.latitude,
                    info.object.properties.longitude,
                ];
                dispatch(
                    setSelectedBuilding({
                        ...info.object.properties,
                        coordinate,
                    })
                );
            }
        },
        [dispatch]
    );

    useEffect(() => {
        let json = [];
        if (data && data.length > 0) {
            const { zoom } = viewState;
            const filteredData = filterData(data, filters);
            const setFeature = (b, color, parent = null) => ({
                type: 'Feature',
                geometry: {
                    type: 'Point',
                    coordinates: [b.longitude, b.latitude], // For geodetic coordinates, X is longitude and Y is latitude
                },
                properties: {
                    ...b,
                    color,
                    parent,
                    radius: (50 * zoom) / 100,
                },
            });

            const collection = [];
            filteredData.forEach((n) => {
                const color = setColor(n);
                if (hasOwn(n, 'children') && !isEmpty(n.children)) {
                    for (let i = 0; i < n.children.length; i++) {
                        collection.push(setFeature(n.children[i], color, n));
                    }
                }

                /**
                 * Note:
                 * This is where the additional filter is being processed that Montgomery does not want buildings that has the status of "not submitted"
                 * if the selected filter is either:
                 * - Site Energy Use Intensity = weather_normalized_site_eui
                 * - Energy Star Score = energy_star_score
                 */
                let addToCollection = true;
                if (n.status === 'not submitted' && (selectedFilter === 'normalized_net_site_eui' || selectedFilter === 'energy_star_score')) {
                    addToCollection = false;
                }

                if (addToCollection) {
                    collection.push(setFeature(n, color));
                }
            });
            json = {
                type: 'FeatureCollection',
                features: collection,
            };
        }
        setMapGeoJson(json);
    }, [data, viewState, selectedFilter, filters, setColor]);

    const { buildingsLayer } = useSelector((state) => state.carto.layers);
    if (buildingsLayer && mapGeoJson) {
        return new GeoJsonLayer({
            id: BUILDINGS_LAYER_ID,
            data: mapGeoJson,
            stroked: false,
            pickable: true,
            pointRadiusUnits: 'pixels',
            getPointRadius: (d) => {
                return d.properties.radius;
            },
            getFillColor: (d) => d.properties.color,
            onHover: (info) => {
                if (info?.object) {
                    const { normalized_status } = info.object.properties.parent
                        ? info.object.properties.parent
                        : info.object.properties;
                    const { building_id, postal_code, street, building_name, normalized_net_site_eui, site_eui, energy_star_score, is_undisclosed } = info.object.properties;
                    info.object = {
                        html: `
                                ${!isEmpty(building_name) ? capitalize(building_name) + '<br>' : ''}
                                <strong>MBID - ${building_id}</strong><br>
                                ${capitalize(street)} ${postal_code}<br>
                                Status - ${capitalize(normalized_status)}<br>
                                Normalized Net Site EUI - ${ isUndisclosed(is_undisclosed, normalized_net_site_eui, numberFormat) }<br>
                                Site EUI - ${isUndisclosed(is_undisclosed, site_eui, numberFormat) }
                                ${energy_star_score ? '<br>Energy Star Score -' : '' } ${energy_star_score || ''}
                            `,
                    };
                }
            },
            onClick: setPopup,
        });
    }
}

export default BuildingsLayer;
