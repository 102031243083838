// int-numberformat dependencies (support for ios v13)
import '@formatjs/intl-locale/polyfill';
import '@formatjs/intl-getcanonicallocales/polyfill';

// int-pluralrules dependencies (support for ios v12)
import '@formatjs/intl-pluralrules/polyfill';
import '@formatjs/intl-pluralrules/locale-data/en';

/*
  Note: `notation` & `compactDisplay` properties are not supported yet by Safari.
  Those require the use of a polyfill: https://www.npmjs.com/package/@formatjs/intl-numberformat
*/
import '@formatjs/intl-numberformat/polyfill';
import '@formatjs/intl-numberformat/locale-data/en';

import { isEmpty } from 'utils/checkValue';

export const currencyFormatter = (value) => {
    return {
        prefix: '$',
        value: Intl.NumberFormat('en-US', {
            maximumFractionDigits: 2,
            minimumFractionDigits: 2,
            notation: 'compact',
            compactDisplay: 'short',
        }).format(isNaN(value) ? 0 : value),
    };
};

export const numberFormatter = (value) => {
    return Intl.NumberFormat('en-US', {
        maximumFractionDigits: 1,
        minimumFractionDigits: 0,
        notation: 'compact',
        compactDisplay: 'short',
    }).format(isNaN(value) ? 0 : value);
};

export const capitalize = (str) => {
    if (isEmpty(str)) return str;
    return str.toTitleCase();
};

export const numberFormat = (number) => {
    if (number === null || number === undefined) return 'N/A';
    const regex = /\B(?=(\d{3})+(?!\d))/g;
    const decimalParts = number.toString().split('.');
    if (typeof decimalParts[1] !== 'undefined') {
        return decimalParts[0].replace(regex, ',') + '.' + decimalParts[1];
    }
    return number.toString().replace(regex, ',');
};

export const emptyValue = (str) => {
    return str === null || str === '' ? 'N/A' : str;
};

export const isUndisclosed = (undisclosed, value, formatter = emptyValue) => {
    if (undisclosed) return 'Undisclosed';
    return typeof formatter === 'function' ? formatter.call(this, value) : emptyValue(value);
};
