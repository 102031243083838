import { useDispatch, useSelector } from 'react-redux';
import { setCompareBuildings } from 'config/appSlice';
import PropTypes from 'prop-types';
import { Button, Paper, Typography, makeStyles } from '@material-ui/core';
import EUIData from 'components/info/EUIData';
import { capitalize, numberFormat } from 'utils/formatter';

const useStyles = makeStyles((theme) => ({
    wrapper: {
        minWidth: '225px',
        padding: theme.spacing(1),
    },
    energy: {
        padding: theme.spacing(1.5),
        textAlign: 'center',
        marginTop: theme.spacing(2),
    },
    button: {
        display: 'block',
        marginTop: theme.spacing(1.5),
        marginRight: 'auto',
        marginLeft: 'auto',
    },
}));

function BuildingInfo(props) {
    const classes = useStyles();
    const dispatch = useDispatch();

    const selectedYear = useSelector((state) => state.app.selectedYear);
    const buildings = useSelector((state) => state.app.compareBuildings);
    const performanceRequirementBaseURL = process.env.REACT_APP_PERFORMANCE_REQUIREMENTS_BASE_URL;
    const performanceRequirementDetailRoute = process.env.REACT_APP_PERFORMANCE_REQUIREMENTS_DETAIL_ROUTE ?? 'details';

    const {
        building_id,
        building_name,
        city,
        final_performance_standard,
        gross_floor_area,
        normalized_status,
        postal_code,
        primary_use_type,
        normalized_net_site_eui,
        street,
        benchmarking_group,
        is_undisclosed,
    } = props.info;

    const handleAddToComparison = () => {
        const info = props.info.parent ? props.info.parent : props.info;
        dispatch(setCompareBuildings(info));
    };

    const hasBuilding = () => {
        const id = building_id;
        const index = buildings.findIndex((b) => b.building_id === id);
        return index > -1;
    };

    return (
        <div className={classes.wrapper}>
            <Typography
                color='textSecondary'
                variant='subtitle2'
                component='h2'
                gutterBottom
            >
                {capitalize(building_name)}
            </Typography>
            {building_name && (
                <Typography color='textPrimary' variant='caption' component='p' gutterBottom>
                    {capitalize(primary_use_type)}
                </Typography>
            )}
            <Typography color='textPrimary' variant='caption' component='p' gutterBottom>
                {capitalize(street)} <br />
                {capitalize(city)}, {postal_code}
            </Typography>
            <Typography variant='caption' component='p' gutterBottom>
                MBID {building_id}
            </Typography>
            <Typography variant='caption' component='p' gutterBottom>
                {numberFormat(gross_floor_area)} ft<sup>2</sup>
            </Typography>
            <Typography variant='caption' component='p' gutterBottom>
                Group - {capitalize(benchmarking_group)}
            </Typography>

            {normalized_status.toLowerCase() !== 'exempt' && (
                <Typography variant='caption' component='p' gutterBottom>
                    Benchmarking Status - {capitalize(normalized_status)}
                </Typography>
            )}

            <Button
                color='primary'
                href={`${performanceRequirementBaseURL}/${performanceRequirementDetailRoute}/${building_id}`}
                target='_blank'
                size='small'
            >
                View Performance Requirements
            </Button>

            {normalized_status.toLowerCase() === 'exempt' && (
                <Paper variant='outlined' className={classes.energy}>
                    <Typography color='textSecondary' variant='body1' component='h4'>
                        Building is Exempt
                    </Typography>
                    <Typography variant='h5' component='h5' gutterBottom>
                        {selectedYear}
                    </Typography>
                </Paper>
            )}

            {is_undisclosed ? (
                <EUIData
                    normalized_net_site_eui={`Undisclosed`}
                    final_performance_standard={final_performance_standard}
                />
            ) : (
                <>
                    {normalized_net_site_eui > 0 && normalized_status === 'in compliance' && (
                        <EUIData
                            normalized_net_site_eui={normalized_net_site_eui}
                            final_performance_standard={final_performance_standard}
                        />
                    )}
                </>
            )}

            <Button
                size='small'
                color='primary'
                variant='contained'
                className={classes.button}
                onClick={handleAddToComparison}
                disabled={normalized_status !== 'in compliance'}
            >
                {hasBuilding() ? 'Remove from' : 'Add to'} Comparison
            </Button>
        </div>
    );
}

BuildingInfo.propTypes = {
    info: PropTypes.object.isRequired,
};

export default BuildingInfo;
