import { Paper, Typography, makeStyles } from '@material-ui/core';
import CompareEui from 'components/info/histograms/CompareEUI';
import PropTypes from 'prop-types';

const useStyles = makeStyles((theme) => ({
    energy: {
        padding: theme.spacing(1.5),
        textAlign: 'center',
        marginTop: theme.spacing(2),
    }
}));

function EUIData(props) {
    const classes = useStyles();
    const {
        final_performance_standard,
        normalized_net_site_eui
    } = props;

    return (
        <>
            <Paper variant='outlined' className={classes.energy}>
                <Typography color='textSecondary' variant='body1' component='h3'>
                    Normalized Net Site EUI/Final Target EUI
                </Typography>
                <Typography variant='h5' component='h4'>
                    {normalized_net_site_eui} / {final_performance_standard}
                </Typography>
                <Typography
                    color='textSecondary'
                    variant='caption'
                    component='p'
                    gutterBottom
                >
                    kBTU/ft<sup>2</sup>
                </Typography>
                <CompareEui
                    net={normalized_net_site_eui}
                    finalTarget={final_performance_standard}
                />
            </Paper>
        </>
    );
}

EUIData.propTypes = {
    normalized_net_site_eui: PropTypes.string.isRequired,
    final_performance_standard: PropTypes.string.isRequired
};

export default EUIData;
