import { useDispatch, useSelector } from 'react-redux';
import { setDataFilters } from 'config/appSlice';
import {
    Box,
    Button,
    Checkbox,
    Chip,
    FormControl,
    InputLabel,
    MenuItem,
    Select,
    makeStyles
} from '@material-ui/core';
import { benchmarking_group } from 'config/defaultValues';
import TouchstoneInput from 'components/common/TouchstoneInput';
import { capitalize } from 'utils/formatter';


const defaultValues = benchmarking_group();
const KEY = defaultValues.key;

const useStyles = makeStyles((theme) => ({
    count: {
        color: theme.palette.grey[200],
    },
    marginTop: {
        marginTop: theme.spacing(2),
    },
}));

function BenchmarkingGroup() {
    const dispatch = useDispatch();

    const selected = useSelector((state) => state.app.filters[KEY].values);
    const classes = useStyles();

    const benchmarkingGroups = [
        'Group 1',
        'Group 2',
        'Group 3',
        'Group 4',
        'Group 5',
        'County'
    ]

    const handleChange = (event) => {
        dispatch(setDataFilters({ key: KEY, value: event.target.value }));
    };

    const handleClearSelected = () => {
        dispatch(setDataFilters({ key: KEY, value: [] }));
    };

    return (
        <>
            <FormControl>
                <InputLabel id='select-benchmarking-group-label' shrink>
                    Benchmarking Group
                </InputLabel>
                <Select
                    multiple
                    labelId='select-benchmarking-group-label'
                    displayEmpty
                    value={selected}
                    onChange={handleChange}
                    input={<TouchstoneInput />}
                    renderValue={(selected) => {
                        if (selected.length === 0) {
                            return <span>All Benchmarking Groups</span>;
                        }
                        return (
                            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                {selected.map((value) => (
                                    <Chip key={value} label={capitalize(value)} />
                                ))}
                            </Box>
                        );
                    }}
                >
                    {benchmarkingGroups &&
                        benchmarkingGroups.map((group) => (
                            <MenuItem key={group} value={group}>
                                <Checkbox checked={selected.indexOf(group) > -1} />
                                {capitalize(group)}
                            </MenuItem>
                        ))}
                </Select>
            </FormControl>
            {selected && selected.length > 0 &&
                <Button
                    size='small'
                    color='primary'
                    variant='contained'
                    className={classes.marginTop}
                    onClick={handleClearSelected}
                >
                    Clear Selected Benchmarking Groups {<>({selected.length})</>}
                </Button>
            }
        </>
    );
}

export default BenchmarkingGroup;
