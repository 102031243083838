import { useDispatch, useSelector } from 'react-redux';
import { setSelectedYear } from 'config/appSlice';
import { FormControl, InputLabel, MenuItem, Select } from '@material-ui/core';
import TouchstoneInput from 'components/common/TouchstoneInput';

const currentYear = new Date().getFullYear();
let years = [];
for (let i = currentYear; i >= 2016; i--) {
    years.push(i);
}

function SelectFilter() {
    const dispatch = useDispatch();
    const selectedYear = useSelector((state) => state.app.selectedYear);

    const handleChange = (event) => {
        dispatch(setSelectedYear(event.target.value));
    };

    return (
        <FormControl>
            <InputLabel id='select-year-label' shrink>
                Reporting Year
            </InputLabel>
            <Select
                labelId='select-year-label'
                value={selectedYear}
                onChange={handleChange}
                input={<TouchstoneInput />}
            >
                {years && years.map((s) => (
                    <MenuItem key={s} value={s}>
                        {s}
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    );
}

export default SelectFilter;
