import { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setDataFilters, setLegend } from 'config/appSlice';
import PieWidgetUI from 'components/widgets/PieWidgetUI';
import {
    Grid,
    IconButton,
    Tooltip,
    Typography,
    makeStyles,
    useTheme,
} from '@material-ui/core';
import checkNotReportingYear from 'utils/checkNotReportingYear';
import { compliance_status } from 'config/defaultValues';
import { status } from 'utils/colors';
import { isEmpty, hasOwn } from 'utils/checkValue';
import { legendSetAmount } from 'utils/setChartDefaults';
import CachedIcon from '@material-ui/icons/Cached';
import InfoIcon from '@material-ui/icons/Info';
import filterData from 'utils/filterData';
import { capitalize } from 'utils/formatter';
import { chain } from 'lodash';

const useStyles = makeStyles((theme) => ({
    title: {
        padding: theme.spacing(1, 0, 1),
        display: 'block',
    },
}));

const defaultValues = compliance_status();
const KEY = defaultValues.key;
const COLORS = defaultValues.colors;
const RANGES = defaultValues.ranges;
const LABELS = defaultValues.labels;

function ComplianceStatus() {
    const theme = useTheme();
    const classes = useStyles();
    const dispatch = useDispatch();

    const data = useSelector((state) => state.app.data);
    const filters = useSelector((state) => state.app.filters);
    const selectedFilter = useSelector((state) => state.app.selectedFilter);
    const selected = useSelector((state) => state.app.filters[KEY].values);
    const selectedYear = useSelector((state) => state.app.selectedYear);
    const [categories, setCategories] = useState([]);
    const [colors, setColors] = useState([]);
    const [labelOverride, setLabelOverride] = useState('notReportingPeriod');
    const handleClearSelected = useRef(null);

    useEffect(() => {
        setLabelOverride(checkNotReportingYear(selectedYear) ? 'notReportingPeriod' : 'reportingPeriod');
    }, [selectedYear])

    useEffect(() => {
        const options = status(theme);
        const categories = [];
        const colors = [];
        let count = {};
        if (data && data.length > 0) {
            const filteredData = filterData(data, filters, [KEY]);
            count = chain(filteredData)
                .filter((t) => t[KEY] != null)
                .countBy(KEY)
                .value();
            for (let i = 0; i < RANGES.length; i++) {
                const key = RANGES[i];
                if (hasOwn(count, key)) {
                    let label = capitalize(key);
                    if (hasOwn(LABELS[labelOverride], key)) label = capitalize(LABELS[labelOverride][key]);
                    categories.push({ name: label, value: count[key], identifier: key });
                    colors.push(options[i]);
                }
            }
        }

        setColors(colors);
        setCategories(categories);
    }, [theme, data, filters, labelOverride]);

    useEffect(() => {
        if (selectedFilter === KEY) {
            const labels = [...RANGES];
            if (!isEmpty(LABELS[labelOverride])) {
                for (let i = 0; i < RANGES.length; i++) {
                    const key = RANGES[i];
                    if (hasOwn(LABELS[labelOverride], key)) {
                        labels.splice(i, 1, LABELS[labelOverride][key])
                    }
                }
            }

            dispatch(
                setLegend({
                    title: 'Benchmarking Compliance Status',
                    categories: legendSetAmount(theme, COLORS, labels),
                })
            );
        }
    }, [dispatch, selectedFilter, theme, labelOverride]);

    const onSelectedCategoriesChange = (categories) => {
        const values = categories.map((c) => c.toLowerCase());
        dispatch(setDataFilters({ key: KEY, value: values }));
    };

    const handleRefreshChart = () => {
        handleClearSelected.current();
    }

    return (
        <>
            <Grid container alignItems={'center'} justifyContent={'space-between'}>
                <Typography
                    component='h5'
                    color='textSecondary'
                    variant='subtitle2'
                    className={classes.title}
                >
                    Benchmarking Compliance
                </Typography>
                <span>
                    <Tooltip
                        title='Reset Benchmarking Compliance Chart.'
                        placement='top'
                    >
                        <IconButton
                            aria-label='Reset Benchmarking Compliance Chart'
                            size='small'
                            color='primary'
                            onClick={handleRefreshChart}
                        >
                            <CachedIcon fontSize='small' />
                        </IconButton>
                    </Tooltip>
                    <Tooltip
                        title='Benchmarking compliance refers to the building’s energy benchmarking report compliance status for the selected reporting year.'
                        placement='top'
                    >
                        <IconButton aria-label='Covered Buildings Info' size='small' color='primary'>
                            <InfoIcon fontSize='small' />
                        </IconButton>
                    </Tooltip>
                </span>
            </Grid>
            <PieWidgetUI
                id='buildingComplianceStatus'
                data={categories}
                colors={colors}
                selectedCategories={selected}
                onSelectedCategoriesChange={onSelectedCategoriesChange}
                handleClearSelected={handleClearSelected}
            />
        </>
    );
}

export default ComplianceStatus;
