import PropTypes from 'prop-types';
import { useTheme } from '@material-ui/core';
import HorizontalHistogramWidgetUI from 'components/widgets/HorizontalHistogramWidgetUI';
import { numberFormat } from 'utils/formatter';

function CompareEui(props) {
    const theme = useTheme();
    const { net, finalTarget } = props;

    const series = [
        { color: theme.palette.neutral[10], value: parseFloat(finalTarget) },
        { color: theme.palette.neutral[6], value: parseFloat(net) },
    ];

    const axis = ['Final Target', 'Normalized Net Site'];

    const handleFormat = (val) => {
        const data = val[0];
        const kbtuValue = numberFormat(data.value);
        return `
            ${data.name} EUI <br />
            ${isNaN(kbtuValue) ? 0 : kbtuValue} (kBtu/ft<sup>2</sup>)
        `;
    };

    return (
        <HorizontalHistogramWidgetUI
            id='compareEUI'
            name='compare_eui_to_site_eui'
            data={series}
            dataAxis={axis}
            isLoading={series.length === 0}
            height={40}
            tooltipFormatter={handleFormat}
        />
    );
}

CompareEui.propTypes = {
    net: PropTypes.string.isRequired,
    finalTarget: PropTypes.string.isRequired,
};

export default CompareEui;
