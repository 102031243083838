import PropTypes from 'prop-types';
import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setSelectedBuilding } from 'config/appSlice';
import { setViewState } from '@carto/react-redux';
import { FormControl, InputLabel } from '@material-ui/core';
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete';
import TouchstoneInput from 'components/common/TouchstoneInput';
import { capitalize } from 'utils/formatter';
import { isEmpty } from 'utils/checkValue';

function Search(props) {
    const { className } = props;
    const dispatch = useDispatch();
    const data = useSelector((state) => state.app.data);
    const [options, setOptions] = useState([]);
    const [value, setValue] = useState(null);

    useEffect(() => {
        const collection = data.filter(
            (d) => d.street !== null && d.latitude !== null && d.longitude
        );
        setOptions(collection);
    }, [data]);

    const handleChange = (_event, value) => {
        if (!isEmpty(value)) {
            setValue(value);
            const coordinate = [value.latitude, value.longitude];
            dispatch(
                setSelectedBuilding({
                    ...value,
                    coordinate,
                })
            );
            dispatch(
                setViewState({
                    latitude: coordinate[0],
                    longitude: coordinate[1],
                    zoom: 14,
                    transitionDuration: 500,
                })
            );
            setValue(null);
        }
    };

    const filterOptions = createFilterOptions({
        stringify: (option) => `${option.building_name} - ${option.street} - ${option.building_id}`,
    });

    return (
        <Autocomplete
            id='search-building'
            aria-label='Search Buildings'
            value={value}
            options={options}
            getOptionLabel={(option) =>
                `${option.building_id} - ${capitalize(option.street)}`
            }
            filterOptions={filterOptions}
            onChange={handleChange}
            renderOption={(option) => (
                <span>
                    {!isEmpty(option.building_name) && 
                        <> {capitalize(option.building_name)} <br/></>
                    }
                    #{option.building_id} <span aria-hidden="true">&middot;</span> {capitalize(option.street)}
                </span>
            )}
            renderInput={(params) => (
                <FormControl>
                    <InputLabel id='search-building-label' shrink>
                        Search
                    </InputLabel>
                    <TouchstoneInput
                        name='building-search'
                        aria-labelledby='search-building-label'
                        ref={params.InputProps.ref}
                        inputProps={params.inputProps}
                        autoFocus
                        placeholder='Search by Address or MBID or Name'
                    />
                </FormControl>
            )}
            className={className}
        />
    );
}

Search.propTypes = {
    className: PropTypes.string,
};

Search.defaultProps = {
    className: '',
};

export default Search;
