import { useSelector } from 'react-redux';
import { GeoJsonLayer } from '@deck.gl/layers';
import { useState } from 'react';
import geojson from 'data/sources/county_boundaries.geojson';

export const COUNTY_LIMITS_LAYER_ID = 'countyLimitsLayer';

function CountyLimitsLayer() {
    const [mapGeoJson] = useState(geojson);
    const { countyLimitsLayer } = useSelector((state) => state.carto.layers);
    if (countyLimitsLayer && mapGeoJson) {
        return new GeoJsonLayer({
            id: COUNTY_LIMITS_LAYER_ID,
            data: mapGeoJson,
            stroked: true,
            filled: false,
            pickable: false,
            getLineColor: [0, 0, 0, 50],
            getLineWidth: 200
        });
    }
}

export default CountyLimitsLayer;
